import * as React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import { Layout } from '../../components/layout'
import { H1, Paragraph } from '../../components/typography'

const Event = ({ data }) => {
    const event = data.event

    return (
        <Layout seo={event?.seo}>
            <HeroWrapper>
                {event.image.asset && <ImageWrapper image={event?.image.asset.gatsbyImageData} alt={event?.image.alt} />}
                {event.title && <H1 textAlign='center'>{event?.title ?? `Event Title`}</H1>}
                {event.tile && <Paragraph margin='0'>{event?.time} at {event?.location} on {event?.date}</Paragraph>}
                {event.website && <Paragraph><a href={event?.website}>Get Tickets</a></Paragraph>}
                {event.description && <DescriptionWrapper><Paragraph>{event?.description}</Paragraph></DescriptionWrapper>}
            </HeroWrapper>
        </Layout>
    )

}

export default Event

export const query = graphql`
    query EventQuery($id: String!) {
        event: sanityEvent(id: { eq: $id }) {
            title
            slug {
                current
            }
            date(formatString: "MMMM Do YYYY")
            description
            location
            time
            website
            image {
                alt
                asset {
                    gatsbyImageData(
                        width: 1440,
                        height: 700,
                    )
                }
            }
        }
    }
`

const HeroWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--space-06) 0;
`

const ImageWrapper = styled(GatsbyImage)`
    max-width: 1440px;
`

const DescriptionWrapper = styled.section`
    margin: 0 auto;
    max-width: 80ch;
    padding: 0 1rem;
`